





























































import logosMixin from '@/mixins/logosMixin'
import CenteredColumnLayout from '@/components/CenteredColumnLayout.vue'
import { InputSetups } from '../../mixins/input-setups'
import { errorNotification } from '@/includes/NotificationService'
import { BaseChatInfo } from '@/includes/types/Chat/types'
import ChatLicense from "@/includes/logic/ChatLicense";

import { SelectOptionData } from 'piramis-base-components/src/components/Pi/types'
import { UseFields } from 'piramis-base-components/src/components/Pi'
import EmptyData from 'piramis-base-components/src/components/EmptyData/EmptyData.vue'

import { Component, Mixins } from 'vue-property-decorator'
import { Route } from 'vue-router'

import { ValidationObserver } from "vee-validate"

@Component({
  'mixins': [ logosMixin ],

  'components': {
    CenteredColumnLayout,
    ValidationObserver,
    EmptyData
  }
})
export default class ChooseChat extends Mixins(UseFields, InputSetups) {
  form: { chatId: string | null } = {
    chatId: null
  }

  hasChats = true

  goToChatBuyPage(): Promise<Route> {
    const { ...query } = this.$route.query

    delete query.returnUrl; // if exists

    return this.$router.push({
      path : `/chat/${ this.form.chatId }/buy/`,
      query: query,
    });
  }

  goToAddChatPage(): Promise<Route> {
    return this.$router.push({ 'name': 'start_add_chat' })
  }

  async getChatsOptions(): Promise<Array<SelectOptionData>> {
    try {
      await this.$store.dispatch("requestChatsInfo", this);
    } catch (error) {
      errorNotification(error)
    }

    const chatsInfo = this.$store.state.chatState.chatsInfo

    if (chatsInfo) {
      const chats: Array<BaseChatInfo> = chatsInfo.chats;

      this.hasChats = chats.length !== 0

      return chats.map((item): SelectOptionData => ({
        label: item.group_title,
        value: item.chat_id,
        image: {
          src: item.group_photo,
        }
      }))
    }

    return []
  }

  isVerifiedChatLicenseBuyQueryParams(query: Route['query']) {
    return Object.values(ChatLicense.LICENSE_TYPES).includes(query?.tariff?.toString()) && Object.values(ChatLicense.MONTH_COUNT).includes(parseInt(query?.monthCount?.toString()));
  }

  created(): void {
    /*
     Example of a right URL: /buy-license/choose-chat/?tariff=starter&monthCount=12
     ?redirectPath - could be exist
     */
    if(Object.keys(this.$route.query).length === 0 || !this.isVerifiedChatLicenseBuyQueryParams(this.$route.query)) {
      this.$router.replace({ name: 'error_404' })
    }
  }
}
